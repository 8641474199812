<template>
    <div class="importCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" @change="deptTreeByDeptGroupCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group of groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-row>
                <el-upload
                    :action="importMemberUrl"
                    :multiple="false"
                    :data="{ deptCode: form.deptCode }"
                    :disabled="disableImportBtn"
                    style="margin-top: 20px"
                    :on-success="handleAfterUploadSuccess"
                    :on-error="handleAfterUploadError"
                >
                    <el-button type="primary" :disabled="disableExportTemplateBtn" @click="exportTemplate" size="small"
                        >下载模板</el-button
                    >
                    <el-tooltip
                        effect="dark"
                        content="每导入一次excel表格就会生成一条新的导入单据"
                        placement="top-start"
                    >
                        <el-button type="primary" :disabled="disableImportBtn" size="small">导入</el-button>
                    </el-tooltip>
                    <el-button type="primary" v-if="importRst.existErrorFlag" @click="exportErrorData" size="small"
                        >下载导入失败数据</el-button
                    >
                </el-upload>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import Utils from 'js/Util';
import { Message } from 'element-ui';

export default {
    name: 'ImportCreate',
    data() {
        return {
            tableData: [],
            form: {
                deptGroupCode: '',
                deptCode: '',
            },
            groups: [],
            depts: [],

            importMemberUrl: this.$efApi.memberImportApi.importMemberUrl,
            //导入返回结果
            importRst: {
                existErrorFlag: false,
                rstCode: '',
            },
        };
    },
    async mounted() {
        this.groups = await this.$efApi.deptGroupApi.managedAllDeptGroups();
    },
    computed: {
        disableImportBtn() {
            return Utils.isEmpty(this.form.deptCode);
        },
        disableExportTemplateBtn() {
            return Utils.isEmpty(this.form.deptCode);
        },
    },
    methods: {
        async deptTreeByDeptGroupCode() {
            this.depts = await this.$efApi.deptApi.managedDeptsByDeptGroupCode(this.form.deptGroupCode);
        },
        exportTemplate() {
            this.$efApi.memberImportApi.exportTemplate(this.form.deptCode);
        },
        handleAfterUploadSuccess(response) {
            if (response && response.status && response.status !== 200 && typeof existErrorFlag === 'undefined') {
                Message({
                    message: response.message,
                    type: 'error',
                    duration: 3 * 1000,
                });
                return;
            }
            const { existErrorFlag, code } = response.data;
            this.importRst.existErrorFlag = existErrorFlag;
            this.importRst.rstCode = code;
            if (existErrorFlag) {
                Message({
                    message: '部分数据导入失败',
                    type: 'error',
                    duration: 3 * 1000,
                });
            } else {
                Message({
                    message: '导入成功',
                    type: 'success',
                    duration: 3 * 1000,
                });
                setTimeout(() => {
                    this.goBackAndReload();
                }, 2000);
            }
        },
        handleAfterUploadError(response) {
            let errMsg = this.isJsonString(response.message) ? JSON.parse(response.message).message : null;
            if (Utils.isEmpty(errMsg)) {
                errMsg = response.message;
            }
            Message({
                message: errMsg,
                type: 'error',
                duration: 3 * 1000,
            });
            return false;
        },
        async exportErrorData() {
            await this.$efApi.memberImportApi.exportCanNotImportData(this.importRst.rstCode);
        },
        isJsonString(str) {
            try {
                if (typeof JSON.parse(str) == 'object') {
                    return true;
                }
            } catch (e) {
                throw new Error();
            }
            return false;
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
